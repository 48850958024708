import { ReactNode, MouseEvent, useEffect, useState, useMemo, ChangeEvent, Dispatch, SetStateAction } from 'react';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import {
  ScanSearch,
  Trash2Icon,
  GripHorizontal,
  Share,
  Eye,
  Upload,
  Trash2,
  FolderInput,
  RotateCcwIcon,
  Square,
  SquareCheck,
  UploadIcon,
} from 'lucide-react';
import { baseSortOptions, albumSortOptions, SortOrderOptions, SortOrderValues, SortState } from '@/types/sort';
import { ColumnDef, getCoreRowModel, Updater, useReactTable } from '@tanstack/react-table';
import { defaultPaginationIndex, PaginationState } from '@/types/pagination';
import { type UniqueIdentifier, useDndMonitor } from '@dnd-kit/core';
import { ToolbarLayoutListButton } from '@/components/toolbars/toolbar-layout-list-button';
import { ToolbarLayoutTileButton } from '@/components/toolbars/toolbar-layout-tile-button';
import { ToolbarSortOrderButton } from '@/components/toolbars/toolbar-sort-order-button';
import { IndeterminateCheckbox } from '@/components/data-table/data-table-checkbox';
import { DataTableGridListView } from '@/components/data-table/data-table-grid-list-view';
import { ReorderAssetsCommand } from '@/hooks/commands/albums/ReorderAssetsCommand';
import { ToolbarZoomOutButton } from '@/components/toolbars/toolbar-zoom-out-button';
import { DataTablePagination } from '@/components/data-table/data-table-pagination';
import { ToolbarZoomInButton } from '@/components/toolbars/toolbar-zoom-in-button';
import { ToolbarFilterButton } from '@/components/toolbars/toolbar-filter-button';
import { useAssetSelection } from '@/context/AssetSelectionContext';
import { useCommandContext } from '@/context/CommandContext';
import { Row, SortingState } from '@tanstack/react-table';
import { Asset, GetAssets } from '@/types/asset';
import { useBrowserStore } from '@/hooks/data/browser/useBrowserStore';
import { useUserSettings } from '@/context/UserSettingsContext';
import { setLocalStorage } from '@/utilities/localStorage';
import { AllSelectedBar } from '@/components/toolbars/all-selected-bar';
import { useUploadStore } from '@/hooks/data/inspector/useUploadStore';
import { AssetThumbnail } from '@/components/asset-thumbnail';
import { useQueryClient } from '@tanstack/react-query';
import { useCurrentPage } from '@/hooks/useCurrentPage';
import { DraggableItems } from '@/types/dragDrop';
import { ToolbarButton } from '@/components/toolbars/toolbar-button';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { CaretDownIcon } from '@radix-ui/react-icons';
import { FilterSelect } from '@/components/ui/filter-select';
import { BackendError } from '@/hooks/useFormErrorHandler';
import { useTreeStore } from '@/hooks/data/tree/useTreeStore';
import { EmptyLayout } from '@/components/empty-layout';
import { GalleryView } from '@/types';
import { useShallow } from 'zustand/react/shallow';
import { RenderGrid } from '@/components/render-grid';
import { EntityType } from '@/types/entity';
import { useDialog } from '@/context/DialogContext';
import { arrayMove } from '@dnd-kit/sortable';
import { FilterBar } from '@/components/toolbars/filter-bar';
import { useRouter } from 'next/router';
import { Skeleton } from '@/components/ui/skeleton';
import { Variants } from '@/types/asset';
import { NodeType } from '@/types/tree';
import { filesize } from 'filesize';
import { useTree } from '@/hooks/data/tree/useTree';
import { Toolbar } from '@/components/toolbars/toolbar';
import { Button } from '@/components/ui/button';
import { Badge } from '@/components/ui/badge';
import { cn } from '@/lib/utils';

export type SelectionEventProps = MouseEvent<HTMLTableCellElement | HTMLDivElement> | undefined;

export type GalleryLayoutProps = {
  data?: { assets?: GetAssets; nodeId?: string; name?: string };
  columns?: Array<ColumnDef<Asset>>;
  isLoading?: boolean;
  emptyState?: ReactNode;
  sort?: SortState;
  handleOnSortChange?: (value: string) => void;
  handleOnSortOrderChange?: () => void;
  queryString?: string;
  handleOnSearch?: (value: ChangeEvent<HTMLInputElement>) => void;
  showFilterInput?: boolean;
  setShowFilterInput?: (showFilterInput: boolean) => void;
  view?: GalleryView;
  onError?: BackendError | Error | null;
  pagination?: PaginationState;
  setPagination?: Dispatch<SetStateAction<PaginationState>>;
  refine?: (value: number) => void;
};

const RowDragHandleCell = ({ row }: { row: Row<Asset> }) => (
  <button className="mx-auto flex h-10 w-full cursor-move items-center justify-center !p-0">
    <GripHorizontal
      className={cn('size-5', row.getIsSelected() ? 'stroke-dark-gray-text' : 'stroke-separator-color', {})}
    />
  </button>
);

export const GalleryLayout = ({
  data,
  columns,
  isLoading = false,
  emptyState,
  sort,
  handleOnSortChange,
  handleOnSortOrderChange,
  queryString,
  handleOnSearch,
  showFilterInput,
  setShowFilterInput,
  view,
  onError,
  pagination,
  setPagination,
  refine,
}: GalleryLayoutProps) => {
  const queryClient = useQueryClient();
  const router = useRouter();

  const [items, setItems] = useState<Array<Asset>>([]);
  const [columnOrder, setColumnOrder] = useState<Array<string>>([
    'drag-handle',
    'select',
    'thumbnail',
    'name',
    'type',
    'fileName',
    'size',
    'nodeId',
    'slug',
    'description',
  ]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const { selectedAssetIds, setSelectedAssetIds, contextAssetSelection, toggleAssetSelection } = useAssetSelection();
  const {
    setCurrentSelectedType,
    currentSelectedType,
    contextNodeSelection,
    selectedAlbum: album,
    toggleNodeSelection,
    setSelectedNodes,
  } = useTree();

  const { isAllSelected, isAllEntitySelected, setIsAllSelected, setIsAllEntitySelected } = useBrowserStore();

  const {
    assetSize,
    galleryView: galleryViewUserSettings,
    setGalleryView,
    setAssetSize,
    visibleColumns,
    setVisibleColumns,
  } = useUserSettings();

  const { apply } = useCommandContext();
  const { isMobile } = useBreakpoint();
  const { openModal } = useDialog();
  const { query } = useRouter();
  const { isSearchPage, isUploadPage, isTrashPage } = useCurrentPage();
  const { pageSize, pageIndex } = query;
  const { nodeId, assets } = data ?? {};
  const { setUploadFolderPath } = useUploadStore();

  const findNodePath = useTreeStore(useShallow((state) => state.findNodePath));
  const setSelectedFolder = useTreeStore((state) => state.setSelectedFolder);
  const setSelectedAlbum = useTreeStore((state) => state.setSelectedAlbum);
  const { setReusableTreeSelectedFolder, selectedAlbum, selectedFolder } = useTreeStore();

  const pathArray = findNodePath(nodeId ?? '', 'name');
  const path = pathArray?.join('/');
  const galleryView = view ? view : galleryViewUserSettings; // Overwrite galleryView with `view` prop if it exists (search page)
  const isListView = useMemo(() => galleryView === GalleryView.LIST, [galleryView]);
  const images = assets?.assets;
  const rowCount = useMemo(() => assets?.pagination?.count, [assets]);

  const assetsLength = useMemo(() => {
    if (assets?.assets) {
      return assets.assets.length;
    } else {
      return 0;
    }
  }, [assets]);

  useEffect(() => {
    if (images) {
      setItems(images);
    }
  }, [images]);

  useEffect(() => {
    if (isAllEntitySelected && assets?.assets) {
      setSelectedAssetIds(assets?.assets.map((asset) => ({ id: asset.id, name: asset.name })));
      setSelectedNodes(assets?.assets.map((asset) => ({ id: asset.id, name: asset.name })));
    }
  }, [
    isAllSelected,
    isAllEntitySelected,
    setSelectedAssetIds,
    setIsAllSelected,
    setIsAllEntitySelected,
    setSelectedNodes,
    assets,
  ]);

  const reorderAssetsCommand = ReorderAssetsCommand(
    selectedAssetIds.map((asset) => asset.id),
    album,
  );

  const handleRightClick = (asset: Asset) => {
    contextNodeSelection({ id: asset.nodeId, name: asset.name });
    setCurrentSelectedType(NodeType.Assets);
    contextAssetSelection({ id: asset.id, name: asset.name });
  };

  const selectAllPageAssets = (selected: boolean) => {
    setIsAllSelected(selected);
    if (selected && assets?.assets) {
      setSelectedAssetIds(assets?.assets.map((asset) => ({ id: asset.id, name: asset.name })));
      setSelectedNodes(assets?.assets.map((asset) => ({ id: asset.id, name: asset.name })));
      setCurrentSelectedType(NodeType.Assets);
    } else {
      setIsAllEntitySelected(false);
      setSelectedAssetIds([]);
      setSelectedNodes([]);
      setCurrentSelectedType(selectedAlbum ? NodeType.Albums : NodeType.Folders);
    }
  };

  const dataIds = useMemo<Array<UniqueIdentifier>>(() => items?.map(({ id }) => id) || [], [items]);

  const defaultColumns: Array<ColumnDef<Asset>> = useMemo(
    () => [
      {
        id: 'drag-handle',
        accessorKey: 'drag-handle',
        header: '',
        meta: {
          cellClassName: '!p-0',
        },
        cell: ({ row }) => isListView && <RowDragHandleCell row={row} />,
        size: 30,
        minSize: 30,
        maxSize: 30,
      },
      {
        accessorKey: 'select',
        id: 'select',
        header: ({ table }) => {
          return (
            <IndeterminateCheckbox
              className="mx-auto hover:cursor-default"
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
              }}
            />
          );
        },
        meta: {
          headerClassName: '!p-0',
          cellClassName: '!p-0',
        },
        cell: ({ row }) =>
          isListView && (
            <IndeterminateCheckbox
              className="mx-auto"
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          ),
        size: 30,
      },
      {
        accessorKey: 'thumbnail',
        header: '',
        meta: {
          cellClassName: '!p-0 relative',
        },
        size: 75,
        cell: ({ row }) => {
          const iconSizes = !isListView
            ? assetSize[0] === 0
              ? 'size-10'
              : assetSize[0] === 20
              ? 'size-12'
              : assetSize[0] === 40
              ? 'size-14'
              : assetSize[0] === 60
              ? 'size-16'
              : 'size-20'
            : 'size-6';

          const overlayIconClassName = !isListView
            ? assetSize[0] === 0
              ? 'size-6'
              : assetSize[0] === 20
              ? 'size-8'
              : assetSize[0] === 40
              ? 'size-10'
              : assetSize[0] === 60
              ? 'size-14'
              : 'size-16'
            : 'size-6';

          const isTrashed = row.original.trashed;

          const type = isSearchPage ? row.original.type : row.original.mimeType;

          return (
            <AssetThumbnail
              thumbnail={row.original.variants?.[Variants.ThumbnailInternal]?.url}
              type={type}
              name={row.original.name}
              draggable={false}
              width={150}
              height={150}
              fill={false}
              {...(isTrashed ? { overlayIcon: Trash2 } : {})}
              imageContainerClassName={cn('bg-panel-background', isListView ? 'absolute inset-0' : 'relative')}
              imageClassName={cn(
                'w-full h-full min-h-full min-w-full transition-all',
                isListView ? 'object-cover' : 'aspect-square object-contain',
                {
                  'opacity-50': isTrashed,
                },
              )}
              iconContainerClassName={cn(
                'flex w-full items-center justify-center',
                isListView ? 'h-full' : 'h-auto aspect-square',
                { 'bg-light-ui-color': isListView && row.getIsSelected() },
              )}
              iconClassName={cn('stroke-light-ui-color', iconSizes, {
                'transition-all object-cover': isListView,
                '!stroke-dark-gray-text': isListView && row.getIsSelected(),
              })}
              playIconClassName={cn({ 'left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2': isListView })}
              overlayIconClassName={overlayIconClassName}
            />
          );
        },
      },
      {
        accessorKey: 'name',
        header: () => <div className="text-center">Name</div>,
        meta: {
          cellClassName: 'text-left dark:text-white text-black font-semibold',
        },
        cell: ({ row }) =>
          isListView ? (
            <p title={row.original.name} className="line-clamp-1 break-all">
              {row.original.name}
            </p>
          ) : (
            <p className="line-clamp-1 grow break-all text-sm font-medium text-black dark:text-white">
              {row.original.name}
            </p>
          ),
      },
      {
        accessorKey: 'type',
        header: () => <div className="text-center">Type</div>,
        meta: {
          cellClassName: 'text-center uppercase',
        },
        size: 70,
        cell: ({ row }) => {
          const type = isSearchPage ? row.original.type : row.original.mimeType?.split('/')[1];

          return isListView ? (
            type
          ) : (
            <Badge
              variant="outline"
              className={cn('uppercase', type && type.length > 10 && '!line-clamp-1 [overflow-wrap:anywhere]')}
            >
              {
                // e.g., 'JPEG', 'PNG', etc.
                type
              }
            </Badge>
          );
        },
      },
      {
        accessorKey: 'fileName',
        header: () => <div className="whitespace-nowrap text-center">Original File Name</div>,
        size: 70,
        cell: ({ row }) => (
          <p
            title={row.original.fileName}
            className={cn('line-clamp-1 break-all text-gray-text', !isListView && 'grow text-xs')}
          >
            {row.original.fileName}
          </p>
        ),
      },
      {
        accessorKey: 'size',
        header: 'Size',
        cell: ({ row }) =>
          row?.getValue('size') && (
            <p className={cn('whitespace-nowrap text-gray-text', !isListView && 'text-xs')}>
              {filesize(row.getValue('size'), { standard: 'jedec', round: 0 })}
            </p>
          ),
      },
      {
        accessorKey: 'nodeId',
        header: 'Asset Id',
        cell: ({ row }) => (
          <p
            title={row.original.nodeId}
            className={cn('line-clamp-1 break-all text-gray-text', !isListView && 'text-xs')}
          >
            {row.original.nodeId}
          </p>
        ),
      },
      {
        accessorKey: 'slug',
        header: 'Slug',
        cell: ({ row }) => (
          <p
            title={row.original.slug}
            className={cn('line-clamp-1 break-all text-gray-text', !isListView && 'text-xs')}
          >
            {row.original.slug}
          </p>
        ),
      },
      {
        accessorKey: 'description',
        header: 'Description',
        cell: ({ row }) => (
          <p
            title={row.original.description}
            className={cn('line-clamp-1 break-all text-gray-text', !isListView && 'text-xs')}
          >
            {row.original.description}
          </p>
        ),
      },
    ],
    [galleryView, assetSize, isListView],
  );

  // Sync table rowSelection with the selectedAssetIds
  const rowSelection = useMemo(() => {
    const selection: Record<string, boolean> = {};

    selectedAssetIds.forEach(({ id }) => {
      selection[id] = true;
    });

    return selection;
  }, [selectedAssetIds]);

  const isDevelopment = process.env.NODE_ENV === 'development' ? true : false;

  const setDataTablePagination = (updater: Updater<PaginationState>) => {
    if (typeof updater !== 'function') {
      return;
    }
    const oldPageInfo = table.getState().pagination;
    const newPageInfo = updater(oldPageInfo);
    setLocalStorage(`${EntityType.Asset}/datatable/pagination/pageSize`, String(newPageInfo.pageSize));
    if (oldPageInfo.pageSize !== newPageInfo.pageSize) {
      newPageInfo.pageIndex = defaultPaginationIndex;
    }
    if (setPagination) {
      setPagination(newPageInfo);
    }
    if (isAllSelected && !isAllEntitySelected) {
      setIsAllSelected(false);
      setSelectedAssetIds([]);
    }
  };

  const table = useReactTable({
    data: items,
    columns: columns ? columns : defaultColumns,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    state: {
      columnVisibility: visibleColumns,
      columnOrder,
      sorting,
      rowSelection: rowSelection,
      pagination,
    },
    rowCount: rowCount,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    onPaginationChange: setDataTablePagination,
    manualPagination: true,
    onColumnVisibilityChange: setVisibleColumns,
    onColumnOrderChange: setColumnOrder,
    onSortingChange: setSorting,
    getRowId: (row) => row.id,
    debugTable: isDevelopment,
    debugHeaders: isDevelopment,
    debugColumns: isDevelopment,
  });

  useDndMonitor({
    onDragEnd(event) {
      const { active, over } = event;
      // Reorder assets when manual sorting is enabled
      if (
        sort?.value === SortOrderValues.MANUAL &&
        over?.data.current?.accepts.includes(active.data.current?.type) &&
        over?.data.current?.type !== DraggableItems.ALBUM &&
        over?.data.current?.type !== DraggableItems.FOLDER &&
        active?.data?.current?.type === DraggableItems.ASSET &&
        active.id !== over.id
      ) {
        setItems((dragData) => {
          const oldIndex = dataIds.indexOf(active.id);
          const newIndex = dataIds.indexOf(over.id);

          const position = Number(pageSize ?? 0) * (Number(pageIndex ?? 1) - 1) + (newIndex + 1); //adding 1 because api accept position from 1 instead of 0
          reorderAssetsCommand.update({ position: position, oldPosition: oldIndex + 1 });

          apply(reorderAssetsCommand);

          return arrayMove(dragData, oldIndex, newIndex);
        });
      }
    },
  });

  const handleSelection = <TData extends Asset>(event: SelectionEventProps, row: Row<TData>) => {
    if (!event) {
      return;
    }
    const keys = {
      metaKey: event.metaKey,
      shiftKey: event.shiftKey,
      ctrlKey: event.ctrlKey,
    };

    if (isAllSelected) {
      setIsAllSelected(false);
      setIsAllEntitySelected(false);
    }

    toggleAssetSelection({
      id: row.original.id,
      name: row.original.name,
      assets: items,
      index: row.index,
      ...keys,
      isAllSelected,
    });

    toggleNodeSelection({
      id: row.original.nodeId,
      name: row.original.name,
      assets: items,
      index: row.index,
      ...keys,
    });

    // TODO: Clean this up
    // trash page data object has types set as 'asset', 'folder', 'album',
    // but data in other gallery views has types set as 'image' and 'video'
    setCurrentSelectedType(
      row.original.type === 'image' || row.original.type === 'video' || row.original.type === 'document'
        ? 'assets'
        : (`${row.original.type}s` as NodeType),
    );
  };

  const handleDoubleClick = (id: string) =>
    router.push({
      pathname: `/asset/[id]`,
      query: {
        id,
        // Conditionally include 'folder' and 'album' in the query parameters
        // Only add 'folder' and 'album' if they exist in the current router query
        ...(query.folder && { folder: query.folder }),
        ...(query.album && { album: query.album }),
      },
    });

  const navigateToUploads = () => {
    if (path) {
      setUploadFolderPath(`/${path}`);
      setReusableTreeSelectedFolder(nodeId);
      setSelectedFolder(undefined);
      setSelectedAlbum(undefined);
      void router.push({
        pathname: `/uploads`,
      });
    }
  };

  return (
    <>
      {!isSearchPage && (
        <>
          <Toolbar>
            <div className="flex flex-1 gap-x-2 border-r border-t border-toolbar-bevel">
              <div className="ml-2 flex grow items-center gap-x-2">
                {!isTrashPage && assets?.assets && assetsLength !== 0 && (
                  <ToolbarButton
                    onClick={() => selectAllPageAssets(!isAllSelected)}
                    title="Select All"
                    icon={isAllSelected ? SquareCheck : Square}
                  />
                )}
                {currentSelectedType === NodeType.Folders && selectedFolder && !isUploadPage && !isTrashPage && (
                  <ToolbarButton onClick={navigateToUploads} label="Upload assets" icon={Upload} />
                )}
                {currentSelectedType === NodeType.Albums && nodeId && (
                  <ToolbarButton
                    onClick={() =>
                      openModal('createShare', 'createShare', {
                        id: nodeId,
                      })
                    }
                    label="Share"
                    icon={Share}
                  />
                )}
                {isTrashPage && (
                  <ToolbarButton
                    disabled={data?.assets?.assets?.length === 0}
                    onClick={() => openModal('emptyTrashConfirmation')}
                    label="Empty trash"
                    icon={Trash2Icon}
                  />
                )}

                {isTrashPage && selectedAssetIds.length > 0 && (
                  <ToolbarButton
                    disabled={selectedAssetIds.length === 0}
                    onClick={() => openModal('restoreTrashConfirmation')}
                    dataCy="toolbar-restore-item-button"
                    label={`Restore item${
                      selectedAssetIds.length === 0 ? '/s' : selectedAssetIds?.length > 1 ? 's' : ''
                    }`}
                    icon={RotateCcwIcon}
                  />
                )}

                {!isTrashPage && selectedAssetIds.length > 0 && (
                  <ToolbarButton
                    onClick={() => openModal('deleteConfirmation')}
                    dataCy="toolbar-delete-remove-asset-button"
                    label={`${album ? 'Remove' : 'Delete'} asset${selectedAssetIds.length > 1 ? 's' : ''}`}
                    icon={Trash2Icon}
                  />
                )}
              </div>
              {setShowFilterInput && (
                <ToolbarFilterButton
                  showFilterInput={showFilterInput}
                  onClick={() => setShowFilterInput && setShowFilterInput(!showFilterInput)}
                />
              )}
            </div>
            <div className="flex divide-x divide-void-gap">
              {sort?.value && handleOnSortChange && !isTrashPage && (
                <FilterSelect
                  value={sort?.value}
                  onValueChange={handleOnSortChange}
                  // Exclude "manual sorting" when folder is selected
                  options={album ? albumSortOptions : baseSortOptions}
                />
              )}
              <div className="flex">
                <div className="flex items-center gap-x-2 border-l border-t border-toolbar-bevel px-3">
                  <DropdownMenu>
                    <DropdownMenuTrigger className="group/display flex items-center">
                      <Eye
                        className={cn(
                          'size-6 min-h-6 min-w-6 stroke-gray-icon-color duration-150 group-hover/display:stroke-gray-icon-hover-color group-data-[disabled]/display:!stroke-gray-icon-color group-data-[state=open]/display:!stroke-highlight-color group-data-[disabled]/display:opacity-50',
                        )}
                        strokeWidth={2.5}
                      />
                      <CaretDownIcon
                        className={cn(
                          'size-8 min-h-8 min-w-8 text-gray-icon-color transition-transform duration-150 group-data-[state=open]/display:rotate-180 group-data-[disabled]/display:opacity-50',
                        )}
                      />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      {table
                        .getAllColumns()
                        .filter(
                          (column) =>
                            column.getCanHide() &&
                            column.id !== 'name' &&
                            column.id !== 'thumbnail' &&
                            !((!isListView && column.id) === 'drag-handle') &&
                            !((!isListView && column.id) === 'select'),
                        )
                        .map((column) => (
                          <DropdownMenuCheckboxItem
                            key={column.id}
                            className="capitalize"
                            checked={column.getIsVisible()}
                            onCheckedChange={(value) => column.toggleVisibility(Boolean(value))}
                          >
                            {column.id === 'nodeId' ? 'Asset Id' : column.id === 'fileName' ? 'File Name' : column.id}
                          </DropdownMenuCheckboxItem>
                        ))}
                    </DropdownMenuContent>
                  </DropdownMenu>

                  {handleOnSortOrderChange && !isTrashPage && (
                    <ToolbarSortOrderButton
                      sort={{ order: sort?.order as SortOrderOptions }}
                      onClick={handleOnSortOrderChange}
                    />
                  )}
                  <ToolbarLayoutListButton galleryView={galleryView} onClick={() => setGalleryView(GalleryView.LIST)} />
                  <ToolbarLayoutTileButton galleryView={galleryView} onClick={() => setGalleryView(GalleryView.TILE)} />
                  <ToolbarZoomOutButton
                    disabled={isListView || assetSize[0] === 0}
                    onClick={() =>
                      setAssetSize((prevSize) => {
                        const newValue = Math.max(prevSize[0] - 20, 0);
                        setAssetSize([newValue]);

                        return [newValue];
                      })
                    }
                  />
                  <ToolbarZoomInButton
                    disabled={isListView || assetSize[0] === 100}
                    onClick={() =>
                      setAssetSize((prevSize) => {
                        const newValue = Math.min(prevSize[0] + 20, 100);
                        setAssetSize([newValue]);

                        return [newValue];
                      })
                    }
                  />
                </div>
              </div>
            </div>
          </Toolbar>
          {isUploadPage && (
            <div className="flex h-12 items-center justify-between bg-status-warning pl-6 text-inverted-text-color">
              <div className="flex gap-x-3">
                <FolderInput className="size-6" strokeWidth={2} />
                <div className="text-base font-bold">Upload folder not selected</div>
              </div>
              <Button
                variant="warning"
                className="h-full justify-center rounded-none border-l-2 border-l-inverted-text-color text-base font-bold"
                data-cy="open-upload-dialog-button"
                onClick={() => {
                  openModal('uploadFolder');
                }}
              >
                Select Folder
              </Button>
            </div>
          )}
          {isAllSelected && assetsLength !== 0 && (
            <AllSelectedBar
              text={`All ${isAllEntitySelected ? rowCount : assetsLength ?? ''} assets ${
                isAllEntitySelected ? (selectedAlbum ? 'in this album' : 'in this folder') : 'on this page'
              } are selected.`}
              buttonText={
                isAllEntitySelected
                  ? 'Clear Selection'
                  : `Select all ${rowCount} asset(s) in ${data?.name ?? currentSelectedType}`
              }
              onClick={() => {
                if (isAllEntitySelected) {
                  setIsAllEntitySelected(false);
                  setIsAllSelected(false);
                  setSelectedAssetIds([]);
                  setCurrentSelectedType(selectedAlbum ? NodeType.Albums : NodeType.Folders);
                } else {
                  setIsAllEntitySelected(true);
                  setCurrentSelectedType(NodeType.Assets);
                }
              }}
            />
          )}
          {showFilterInput && handleOnSearch && (
            <FilterBar showFilterInput={showFilterInput} queryString={queryString} onChange={handleOnSearch} />
          )}
        </>
      )}
      {isLoading ? (
        <RenderGrid
          isSearchPage={isSearchPage}
          isMobile={isMobile}
          galleryView={view ? view : galleryView}
          assetSize={assetSize}
        >
          {Array.from(Array(16)).map((_, index) => (
            <div
              key={`gallery-skeleton-${index}`}
              className={cn(
                isMobile
                  ? isListView
                    ? 'grid grid-cols-[100px_1fr] gap-5 space-y-3'
                    : 'grid grid-rows-[1fr_auto] gap-3'
                  : isListView
                  ? 'grid grid-cols-[100px_1fr] gap-5 space-y-3'
                  : 'grid gap-2',
              )}
            >
              <Skeleton className="flex aspect-square h-auto w-full bg-neutral-100 dark:bg-neutral-900" />
              <div className="!mt-0 flex flex-col justify-center gap-2 text-sm">
                <Skeleton className="h-4 w-11/12 bg-neutral-100 dark:bg-neutral-900" />
                <Skeleton className="h-6 w-9/12 bg-neutral-100 dark:bg-neutral-900" />
              </div>
            </div>
          ))}
        </RenderGrid>
      ) : (
        <>
          {onError ? (
            <div className="flex h-full flex-col items-center justify-center">
              <h1 className="mb-4 text-3xl font-bold text-neutral-950 dark:text-neutral-50">
                Oops! Something went wrong.
              </h1>
              <p className="mb-8 text-lg text-neutral-600 dark:text-neutral-400">Please try again later.</p>
              {!isSearchPage && (
                <Button onClick={() => queryClient.invalidateQueries({ queryKey: ['assetData'] })}>Retry</Button>
              )}
            </div>
          ) : items?.length ? (
            <>
              <DataTableGridListView
                parentNodeId={nodeId}
                galleryView={view ? view : galleryView} // /search page overrides gallery view
                assetSize={assetSize}
                columnVisibility={visibleColumns}
                table={table}
                data={items}
                selectedAssetIds={selectedAssetIds}
                dataIds={dataIds}
                handleClick={handleSelection}
                handleRightClick={handleRightClick}
                isListView={isListView}
                rowCount={rowCount}
                {...(!isTrashPage && { handleDoubleClick })} // Disable double click for /trash page
              />
              <DataTablePagination table={table} refine={refine} className="px-3 py-2" />
            </>
          ) : emptyState ? (
            emptyState
          ) : (
            <EmptyLayout
              icon={ScanSearch}
              title={
                !selectedFolder && !selectedAlbum
                  ? 'Nothing selected'
                  : `Empty ${currentSelectedType === NodeType.Folders ? 'Folder' : 'Album'}`
              }
              message={
                !selectedFolder && !selectedAlbum
                  ? 'Select a folder or an album to see assets'
                  : `The selected ${currentSelectedType === NodeType.Folders ? 'folder' : 'album'} does not contain any
                assets.`
              }
              onClick={
                !selectedFolder && !selectedAlbum
                  ? undefined
                  : currentSelectedType === NodeType.Folders
                  ? navigateToUploads
                  : undefined
              }
              buttonIcon={UploadIcon}
              buttonText={'Upload Assets'}
            />
          )}
        </>
      )}
    </>
  );
};
