import React, { FC } from 'react';

import { Button } from '@/components/ui/button';

export const AllSelectedBar: FC<{
  text: string;
  buttonText: string;
  onClick: () => void;
}> = ({ text, buttonText, onClick }) => {
  return (
    <div className="flex h-8 items-center justify-center gap-x-2 bg-highlight-color px-2 dark:bg-dark-highlight-color">
      <div className="text-xs">{text}</div>
      <Button
        variant="ghost"
        className="flex gap-x-2 px-2 text-xs font-bold hover:bg-transparent hover:underline dark:hover:bg-transparent"
        data-cy="select-all-toolbar-button"
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </div>
  );
};
