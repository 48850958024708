import { Button } from '@/components/ui/button';
import { LucideIcon } from 'lucide-react';

export const EmptyLayout = ({
  icon: Icon,
  title,
  message,
  onClick,
  buttonIcon: ButtonIcon,
  buttonText,
}: {
  icon: LucideIcon;
  title: string;
  message: string;
  onClick?: () => void;
  buttonIcon: LucideIcon;
  buttonText: string;
}) => {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-2">
      <Icon className="mb-2 size-40 text-gray-icon-color" strokeWidth={1} />
      <h4 className="text-balance text-center text-2xl text-text-color">{title}</h4>
      <div className="max-w-lg text-balance px-16 text-center text-gray-text">{message}</div>
      {onClick && (
        <Button
          className="mt-4 rounded-xl bg-dark-highlight-color font-bold text-inverted-text-color dark:bg-highlight-color"
          onClick={onClick}
        >
          <ButtonIcon className="mr-2 size-6" />
          {buttonText}
        </Button>
      )}
    </div>
  );
};
